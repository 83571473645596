<template>
  <v-card flat>
    <b> &nbsp;&nbsp; APM Test at {{ date }}<br /> </b>
    <v-row style="margin: 2px">
      <v-col dense cols="12" sm="6" md="8">
        <v-row
          align="baseline"
          dense
          no-gutters
          v-for="item in apms"
          :key="item.id"
        >
          <v-col dense cols="12" sm="3" md="4">
            <span>
              {{ item.label }}
            </span>
          </v-col>
          <v-col dense cols="12" sm="2" md="2">
            <v-text-field
              class="rowh0"
              outlined
              dense
              v-model="item.value"
              :readonly="true"
            >
            </v-text-field>
          </v-col>
          <v-col style="margin-left: 2px" dense cols="12" sm="6" md="6">
            <v-text-field label="Comment" outlined dense v-model="item.comment">
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    apms: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    auth() {
      let a = "01007";

      return a;
    },
    date() {
      if (this.apms.length > 0) return this.apms[0].testdate;
      else return null;
    },
  },
  watch: {},
  methods: {},
  created() {
    this.pressure_unit = this.$store.state.options[0].pressure;
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
  margin-left: 2px;
}
</style>
